.chosen {
    box-shadow: 4px 4px 16px 0px rgba(219, 59, 255, 1);
    background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, #61dafb, darkorchid) border-box;
    border-radius: 1em;
    border: 4px solid transparent;
    height: 100%;
}

.chosenImg {
    max-height: 400px;
}

.unchosenStyle {
    max-height: 300px;
    height: 300px;
}

.unchosenImg {
    width: 240px;
    height: 300px;
    object-fit: cover;
}

.unchosenImg {
    filter: grayscale(1);
}