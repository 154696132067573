.button {
    --purple: #db07d1;
    --pink: #f2056f;
    --blue: #61dafb;

    appearance: none;
    border: none;
    cursor: pointer;
    background-color: #acc7ed;
    color: #fff;
    border-radius: 60px;
    outline: none;
    margin: 0;
    padding: 12px 12px;
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: -1px;
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    z-index: 2;
    text-shadow: 1px 1px 2px black;
}

.shapes {
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-radius: 60px;
    background: linear-gradient(
            60deg,
            var(--blue) 0%,
            #d6cbf6 30%,
            var(--pink) 70%
    );
}

.blush {
    position: absolute;
    bottom: -15px;
    width: 100px;
    height: 30px;
    filter: blur(20px);
}

.pink {
    right: 20px;
    background: var(--purple);
}

.blue {
    left: 20px;
    background: var(--blue);
}

.container {
    position: absolute;
    top: -100px;
    bottom: -100px;
    left: -100px;
    right: -100px;
    width: calc(100% + 200px);
    pointer-events: none;
}

.shapes canvas {
    position: absolute;
    width: 100%;
    height: 100%;
}

.label {
    width: 280px;
    padding: 20px 0;
    transform: translateZ(0);
    font-weight: 700;
    z-index: 1;
}

.default {
    display: block;
}

.number {
    padding: 20px 0;
    width: 88px;
    position: relative;
    transform: translateZ(0);
}

.number:before {
    content: "";
    position: absolute;
    left: 0;
    top: 1px;
    bottom: 1px;
    width: 1px;
    background-color: #35373f;
}

.current {
    color: #8a8d9b;
    opacity: 1;
    display: block;
}

.new {
    color: #fbfaaa;
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    display: block;
}

.add {
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    opacity: 0;
    transform: translateY(38px);
    pointer-events: none;
    color: #d0d0db;
    display: block;
}
