.siderContainer {
    height: 100vh !important;
    position: fixed !important;
    left: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
}

.mainContainer {
    width: 100vw;
}

.textContainer {
    padding: 24px;
    background: #001529;
    border-radius: 12px;
    color: white;
    margin: 12px 18px;
}

.textContainer > * {
    color: white !important;
}

.whiteText {
    color: white !important;
}

.mt48 {
    margin-top: 48px;
}