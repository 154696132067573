body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Protest Strike", 'Roboto', 'Oxygen', sans-serif !important;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

*:before,
*:after {
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#tsparticles {
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: #ffffff;
}

.ant-menu-title-content { color: #BFBFBF; }

@media (max-width: 940px) {

  .ant-float-btn-content {
    padding: 0 !important;
    width : 150px;
  }

  .ant-float-btn {
    padding: 4px !important;
  }

  .ant-tour-header {
    padding: 24px !important;
  }
}

.ant-tour {
  width: auto;
  max-width: 70vw;
}