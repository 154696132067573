.container {
    padding: 48px;
    position: relative;
    overflow: hidden;
}

.centerText {
    text-align: center;
    width: 100%;
    display: inline-block;
    font-size: 18px;
}

.icon {
    color: #2B4481FF;
}

.icon > div {
    font-size: 32px;
}

.blob {
    position: absolute;
    right: 0;
    top: 0;
}

.blobReversed {
    position: absolute;
    left: 0;
    bottom: -100px;
    transform: rotate(-180deg);
}
