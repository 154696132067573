.kufam {
    font-family: "Poppins", sans-serif;
    letter-spacing: 0.2em;
    font-weight: 900;
    font-style: normal;
    color: white;
}

.textColor {
    color: #bc83fc
}

.icon {
    font-size: 1.5em !important;
}

.icon > svg {
    fill: #bc83fc
}