.container {
    background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, #61dafb, darkorchid) border-box;
    border-radius: 1em;
    border: 4px solid transparent;
    box-shadow: 0 11px 35px 2px rgba(0, 0, 0, 0.14);
    margin: 16px 16px;
    padding: 24px;
    position: relative;
    overflow: hidden;
}