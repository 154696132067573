.container {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
}

.content {
overflow: visible !important;
    max-width: 1300px;
    width: 100%;
    height: 100%;
    margin-top: 100px;
}

.anchorSticky {
    position: sticky;
    position: -webkit-sticky;
    top: calc(50vh - 252px);
    max-height: 576px;
    z-index: 1;
    width: 100%;
}

.anchorSticky > * {
    font-size: 2em !important;
}

.videoWrapper {
    margin: 0;
    min-height: 576px;
    padding: 0;
    position: sticky;
    position: -webkit-sticky;
    top: calc(50vh - 252px);
    width: 50%;
    max-height: 576px;
    z-index: 1;
}

.videoWrapper:before {
    background-color: #f4f4fe;
    border-radius: 1.5rem;
    content: "";
    display: block;
    flex: 1;
    padding-bottom: 89.3939%;
}

.video {
    opacity: 0;
    border-radius: 1.5em;
    transition: opacity 0.3s linear, box-shadow 0.3s linear;
    height: auto;
    left: 50%;
    -o-object-fit: contain;
    object-fit: contain;
    overflow: hidden;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 95%;
}

.activeVideo {
    opacity: 1;
}

.inactiveVideo {
    opacity: 0;
}

.navigationButton {
    font-size: 1.5em;
    padding: 12px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    border: none;
    color: inherit;
    cursor: pointer;
    display: inline;
    letter-spacing: inherit;
    margin: 5px;
    position: relative;
    text-align: inherit;
    text-decoration: none;
}

.navigationButtonSpan:before {
    align-items: center;
    border: 1px solid transparent;
    border-radius: 0.75rem;
    color: #bc83fc;
    content: "0" attr(data-count);
    flex: 0 0 auto;
    font-size: 1rem;
    height: 50px;
    line-height: 1;
    padding-top: 0.25rem;
    transition: color 0.5s linear,border-color 0.5s linear;
}

.buttonSvg {
    left: -6px;
    opacity: 0;
    position: absolute;
    top: -2px;
    transform: rotate(-90deg);
    stroke: #bc83fc;
    stroke-width:3;
    stroke-linecap:round;
}

.buttonSvg[data-active=true] {
    opacity: 1;
}

.buttonSvg[data-active=true] > path {
    stroke: #bc83fc;
    stroke-width: 4;
    stroke-dashoffset: 358;
    transition-duration: 4s;
    transition-property: stroke-dashoffset;
    transition-timing-function: linear;
}

.buttonSvg > path {
    stroke-dasharray: 179;
    stroke-dashoffset: 179;
}

.cardIcon {
    padding: 14px;
    background-color: #f4f4fe;
    color: #bc83fc;
}

.card {
    padding: 12px;
    text-align: left;
    color: gray;
    font-size: 3em;
    min-height: 600px;
    width: 100%;
}

.cardTitle {
    font-size: 32px;
    font-weight: 700;
}

.cardDesc {
    margin-top: 30px;
    font-size: 24px;
    color: gray;
}

@media (max-width: 940px) {

    .videoWrapper {
        position: relative;
        top: inherit;
        left: inherit;
    }

    .content {
        overflow: hidden !important;
    }

    .video {
        top: initial;
        transform: initial;
        left: 0;
        width: 100%;
    }

    .videoWrapper:before {
        padding-bottom: 0;
    }

    .card {
        height: auto;
        min-height: auto;
        text-align: center;
    }
}