.menuButton {
    float: right;
    height: 32px;
    width: 32px;
    font-size: 1.5em;
    margin: 0;
    padding: 0;
    display: none;
    background: none;
    color: white;
}

.menuButton:hover {
    color: #bc83fc !important;
}

.header {
    z-index: 2;
    height: 100%;
    align-items: center;
    display: flex;
    width: 100vw;
}

.rightMenu {
    float: right;
}

.drawer {
    background-color: #001529;
    color: white;
}

.drawer > * {
    color: white !important;
    fill: white !important;
}

@media (max-width: 767px) {
    .menuButton {
        display: block;
    }
    .rightMenu {
        display: none;
    }
}