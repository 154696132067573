.wave {
    height: 100%;
    position: relative;
    background-image: linear-gradient(215deg, #2B4481FF 0%, #bc83fc 74%);
}

.homePageHeroFlex {
    min-height: 100vh;
    word-wrap: break-word;
    margin: 0 auto;
}

.wave > svg {
    position: absolute;
    bottom: -5px;
    left: 0;
    z-index: 0;
}

.blob {
    position: absolute;
    right: 0;
    top: -10px;
    z-index: 0;
}

.mockUpCard {
    position: initial;
    border-top: none;
    border-width: 8px;
    border-radius: 0 0 0 12px;
    height: min-content;
    border-color: #001529;
    background: transparent;
    padding: 0;
}


.integrationContainer {
    margin: 92px 0;
}

.card {
    background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, #61dafb, darkorchid) border-box;
    border-radius: 1em;
    border: 4px solid transparent;
    width: 100%;
    max-width: calc(100% - 30vw);
    text-align: left;
    position: relative;
    font-size: 18px;
    font-weight: 400;
    line-height: 2;
    color: gray;
}

.card ul {
    list-style-type: none;
    margin-left: 10px;
}

.card li {
}

.card li:before {
    margin-left: -10px;
}

.flexContainer {
    width: 100%;
}

.text {
    width: 100%;
    max-width: calc(100% - 30vw);
    text-align: center;
    white-space: pre-wrap;
    letter-spacing: .01em;
    font-size: 34px !important;
    line-height: 1;
    font-weight: 700;
    color: rgb(51 65 85) !important;
}

.card :is(h1, h2, h3, h4, h5, h6){
    color: #2B4481FF;
    white-space: pre-wrap;
}

.leftIcon {
    float: left;
    margin-left: -160px;
}

.rightIcon {
    float: right;
    margin-right: -160px;
}

.icon {
    margin-top: -40px;
    display: block;
    width: 320px;
    max-height: 320px;
}

.customShapeDivider {
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

@media (max-width: 940px) {
    .leftIcon {
        margin-left: 0;
    }

    .rightIcon {
        margin-right: 0;
    }

    .icon {
        margin-top: 0;
        width: 256px;
        max-height: 256px;
    }
}