.container {
    width: 100%;
    min-width: 20vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px
}

.icon {
    margin: 0 8px;
    width: 32px;
}

.image {
    max-width: 300px;
    max-height: 400px;
}

.select {
    width: 300px;
    margin: 16px 0;
}