.carousel {
    width: 100%;
    padding: 40px 30px;
    margin-bottom: 40px;
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.carousel__container {
    white-space: nowrap;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}


.carouselItem {
    width: 200px;
    height: 250px;
    border-radius: 20px;
    background-color: #95bcd6;
    overflow: hidden;
    margin-right: 10px;
    margin-top: 70px;
    display: inline-block;
    cursor: pointer;
    -webkit-transition: 1000ms all;
    transition: 1000ms all;
    -webkit-transform-origin: center left;
    transform-origin: center left;
    position: relative;
    color: #fff
}

.carouselItemExpand {
    -webkit-transition: 300ms all;
    transition: 300ms all;
    box-shadow: 4px 4px 16px 4px rgba(219, 59, 255, 0.6);
    background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, #61dafb, darkorchid) border-box;
    border-radius: 1em;
    border: 4px solid transparent;
}

.carouselItem:hover ~ .carouselItem {
    -webkit-transform: translate3d(100px, 0, 0);
    transform: translate3d(100px, 0, 0);
}

.carousel__container:hover .carouselItem {
    opacity: 0.3;
}

.carousel__container:hover .carouselItem:hover {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 1;
}

.carouselItem__img {
    width: 200px;
    height: 250px;
    -o-object-fit: cover;
    object-fit: cover;
}

.carouselItem__details {
    background: -webkit-gradient(
            linear,
            left bottom,
            left top,
            from(rgba(0, 0, 0, 0.9)),
            to(rgba(0, 0, 0, 0))
    );
    background: linear-gradient(
            to top,
            rgba(0, 0, 0, 0.9) 0%,
            rgba(0, 0, 0, 0) 100%
    );
    font-size: 1em;
    opacity: 0;
    -webkit-transition: 450ms opacity;
    transition: 450ms opacity;
    padding: 10px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.carouselItem__details:hover {
    opacity: 1;
}

.carouselItem__details span {
    /* width: 10px;
    height: 10px; */
    font-size: 0.9rem;
    color: #2ecc71;
    /* background-color: white; */
}

.carouselItem__details .controls {
    padding-top: 140px;
}

.carouselItem__details .carouselItem__details--title,
.carouselItem__details--subtitle {
    color: #fff;
    margin: 5px 0;
}

.minHeight {
    min-height: 100px;
}

@media (max-width: 940px) {
    .carouselItem:hover ~ .carouselItem {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    .carousel__container:hover .carouselItem:hover {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}